import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  tap,
} from 'rxjs/operators';
import { UserType } from 'src/app/login/models/user-type.enum';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { ActiveSessionSearch } from '../models/active-session-search.model';
import { ReportsService } from '../reports.service';
import { ActiveSessionsDataSource } from './active-sessions.datasource';

@Component({
  templateUrl: './active-sessions.component.html',
  styleUrls: ['./active-sessions.component.scss'],
})
export class ActiveSessionsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingCsv: boolean = false;
  dataSource!: ActiveSessionsDataSource;
  displayedColumns: string[] = [
    'userId',
    'names',
    'companyName',
    'userName',
    'numberOfActiveSessions',
    'ipAddress',
    'userAgent',
    'dateCreated',
    'expiryDate',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    startDate: null,
    endDate: null,
    individuals: true,
    businesses: true,
    admins: true,
  });
  lastUserId: number | null = null;

  shouldDisplayUserId(currentUserId: number): boolean {
    if (this.lastUserId !== currentUserId) {
      this.lastUserId = currentUserId;
      return true;
    }
    return false;
  }
  orderField: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private formSub?: Subscription;
  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private reportsService: ReportsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataSource = new ActiveSessionsDataSource(
      this.reportsService,
      this.errorService
    );

    this.route.queryParams
      .pipe(
        first(),
        tap((params) => {
          if (!params.isPdf !== undefined) {
            this.startDateControl.setValue(params.dateFrom);
            this.endDateControl.setValue(params.dateTo);
            this.searchControl.setValue(params.searchText);
            this.orderField = params.orderField || '';

            // sets sortDirection
            if (params.orderDescending) {
              this.sortDirection =
                params.orderDescending === 'true' ? 'desc' : 'asc';
            }

            this.dataSource.loadActiveSessions(params);
          } else {
            this.dataSource.loadActiveSessions({ limit: 20 });
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.startDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.individualsControl.valueChanges,
      this.businessesControl.valueChanges,
      this.adminsControl.valueChanges
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadActiveSessionsPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadActiveSessionsPage()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  onGenerateCsv() {
    const paramsCsv = this.generateActiveSessionsReqParams();
    paramsCsv.limit = this.dataSource.getCount();
    this.isLoadingCsv = true;
    this.reportsService
      .getActiveSessionsCsv(paramsCsv)
      .pipe(finalize(() => (this.isLoadingCsv = false)))
      .subscribe(
        (csv) => {
          var element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
          );
          element.setAttribute('download', 'active-sessions.csv');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        },
        () => this.errorService.showErrorDialog()
      );
  }

  private loadActiveSessionsPage(): void {
    this.dataSource.loadActiveSessions(this.generateActiveSessionsReqParams());
  }

  private generateActiveSessionsReqParams(): ActiveSessionSearch {
    // set value for userType
    let userType = [];
    if (this.individualsControl.value) {
      userType.push(UserType.PERSONAL);
    }
    if (this.businessesControl.value) {
      userType.push(UserType.CORPORATE);
    }
    if (this.adminsControl.value) {
      userType.push(
        UserType.ADMINISTRATOR,
        UserType.ONBOARDING_AGENT,
        UserType.OPERATIONS_MANAGER,
        UserType.AMLCO,
        UserType.BOD
      );
    }

    const params: ActiveSessionSearch = {
      page: this.paginator.pageIndex + 1, // backend counts pages from 1 and mat-paginator from 0
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      dateFrom: this.startDateControl.value
        ? DateTime.fromISO(this.startDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      dateTo: this.endDateControl.value
        ? DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      userType,
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
    };
    return params;
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
  get individualsControl(): FormControl {
    return this.filtersGroup.get('individuals') as FormControl;
  }
  get businessesControl(): FormControl {
    return this.filtersGroup.get('businesses') as FormControl;
  }
  get adminsControl(): FormControl {
    return this.filtersGroup.get('admins') as FormControl;
  }
}
