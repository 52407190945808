import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, tap, timeout } from 'rxjs/operators';
import { IdleService } from 'src/app/idle.service';
import { UserEventsService } from 'src/app/shared/services/user-events.service';
import { environment } from 'src/environments/environment';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { ClientIpAddress } from '../models/client-ip-address.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from 'src/app/shared/services/encryption.service';
import { NA } from 'src/app/shared/helpers/various-helpers.helper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = `${environment.BACKEND_URL}/login`;
  public authenticatedUserSubject =
    new BehaviorSubject<AuthenticatedUser | null>(null);

  constructor(
    private http: HttpClient,
    private idleService: IdleService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private userEventsService: UserEventsService,
    private encryptionService: EncryptionService,
    private snackBar: MatSnackBar
  ) {
    if (this.isLoggedIn()) {
      this.idleService.startIdleChecking();
    }
  }

  // returns observable with authenticatedUser (value may change)
  getAuthenticatedUserObs(): Observable<AuthenticatedUser | null> {
    return this.authenticatedUserSubject.asObservable();
  }

  // return observable with authenticatedUser from server (once)
  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    const url = `${environment.BACKEND_URL}/authenticated-user`;
    return this.http.get<AuthenticatedUser>(url).pipe(
      tap((user) => {
        this.authenticatedUserSubject.next(user);
        this.userEventsService.startListening((path) =>
          this.setTokenInCookie(path)
        );
      })
    );
  }

  getIpAddress(): Observable<any> {
    return this.http.get('https://api.ipify.org?format=json').pipe(
      timeout(30000),
      catchError((e) => {
        return of(null);
      })
    );
  }

  async checkOtpIfRequired(
    uName: string,
    passwd: string,
    isSmsResend: boolean = false
  ): Promise<Observable<{ otpRequired: boolean }>> {
    const deviceToken = localStorage.getItem('deviceToken');
    const ipPromise: ClientIpAddress = await this.getIpAddress().toPromise();
    const clientIp = ipPromise ? ipPromise.ip : NA;

    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({
        uName,
        passwd,
        clientIp,
        deviceToken,
        isSmsResend,
      });
    return this.http.post<{ otpRequired: boolean }>(
      `${this.baseUrl}/otp`,
      cipherModel
    );
  }

  async logIn(
    uName: string,
    passwd: string,
    otp: string,
    rememberDevice: boolean
  ): Promise<Observable<{ numberOfActiveSessions: number }>> {
    const deviceToken = localStorage.getItem('deviceToken');
    const ipPromise: ClientIpAddress = await this.getIpAddress().toPromise();
    const clientIp = ipPromise ? ipPromise.ip : NA;
    const userAgent = window.navigator.userAgent;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({
        uName,
        passwd,
        clientIp,
        otp,
        rememberDevice,
        deviceToken,
        userAgent,
      });
    return this.http
      .post<{
        refresh: string;
        token: string;
        newDeviceToken: string;
        numberOfActiveSessions: number;
      }>(this.baseUrl, cipherModel)
      .pipe(
        tap(({ refresh, token, newDeviceToken }) =>
          this.saveLoginCredentials(token, refresh, newDeviceToken)
        ),
        // TODO: authenticated user should be returned with tokens
        mergeMap(({ numberOfActiveSessions }) =>
          this.getAuthenticatedUser().pipe(mapTo({ numberOfActiveSessions }))
        )
      );
  }

  logOut(
    isAutomaticLogout = false,
    isWrongPassword = false,
    isForSecurityReasons = false,
    isMobilePhoneChange = false
  ): void {
    const url = `${this.baseUrl}/logout`;
    const refreshToken = sessionStorage.getItem('refresh');
    // check if token exists to avoid infinite loop in interceptor,
    // which would repeatedly call logout because a 401 is returned when no refresh token exists
    if (refreshToken) {
      // revoke refresh token in backend
      this.http.post<void>(url, { refresh: refreshToken }).subscribe();
    }
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh');
    this.snackBar.dismiss(); // close all snackbars on logout/idle
    this.router.navigate(['login'], {
      state: {
        isAutomaticLogout,
        isWrongPassword,
        isForSecurityReasons,
        isMobilePhoneChange,
      },
    });
    this.idleService.stopIdleChecking();
    this.authenticatedUserSubject.next(null);
    this.userEventsService.stopListening();
  }

  // checks both token and refreshToken
  isLoggedIn(): boolean {
    const refreshToken = sessionStorage.getItem('refresh') ?? undefined;
    const isExpired =
      !this.jwtHelper.isTokenExpired() ||
      !this.jwtHelper.isTokenExpired(refreshToken);
    return isExpired;
  }

  refreshToken(): Observable<{ token: string; refresh: string }> {
    const url = `${this.baseUrl}/refresh`;
    const refreshToken = sessionStorage.getItem('refresh');
    return this.http
      .post<{ token: string; refresh: string }>(url, {
        token: refreshToken,
      })
      .pipe(
        tap(({ token, refresh }) => this.saveLoginCredentials(token, refresh))
      );
  }

  saveLoginCredentials(
    token: string,
    refresh: string,
    newDeviceToken?: string
  ): void {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refresh', refresh);
    if (newDeviceToken) {
      localStorage.setItem('deviceToken', newDeviceToken);
    }
    this.idleService.startIdleChecking();
    this.userEventsService.startListening((path) =>
      this.setTokenInCookie(path)
    );
  }

  private getActiveToken(): Observable<string | Promise<string>> {
    if (this.jwtHelper.isTokenExpired()) {
      return this.refreshToken().pipe(map(({ token }) => token));
    } else {
      return of(this.jwtHelper.tokenGetter());
    }
  }

  setTokenInCookie(path: string = '/'): Observable<void> {
    return this.getActiveToken().pipe(
      map((token) => {
        var date = new Date();
        date.setSeconds(date.getSeconds() + 10);
        document.cookie = `jwtToken=${
          token || ''
        }; expires=${date.toUTCString()}; path=${path}`;
      })
    );
  }
}
