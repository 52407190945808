<div class="main" fxLayoutAlign="space-between start">
  <div fxLayout="column" class="logo-container">
    <img
      data-testid="ecredo-icon-button"
      src="assets/img/logo.svg"
      alt="eCredo logo"
      routerLink="/dashboard"
    />
    <div data-testid="program-name-text" class="program">
      {{ program | titlecase }}
    </div>
  </div>

  <div
    [ngSwitch]="!!authenticatedUser"
    fxLayoutAlign="space-between center"
    fxLayoutGap="12px"
  >
    <ng-container *ngSwitchCase="true">
      <div *ngIf="program === getAdminType(authenticatedUser?.type) && balance">
        <h2>SMS Balance: {{ balance }}</h2>
      </div>
      <button
        mat-icon-button
        class="notification-button"
        [matMenuTriggerFor]="notification"
        (menuOpened)="onNotificationsOpened()"
      >
        <img
          src="assets/img/notification.svg"
          alt="notification icon"
          class="notification"
        />
        <div class="counter" *ngIf="hasUnreadNotifications">
          <div class="count"></div>
        </div>
        <mat-menu #notification="matMenu">
          <div class="notification-list">
            <div *ngFor="let notification of notifications">
              <div class="dot" *ngIf="notification.isUnread"></div>
              <div class="notification-item">
                <div fxLayoutAlign="space-between start">
                  <span class="notification-title">{{
                    notification.title
                  }}</span>
                  <span>{{
                    notification.dateCreated | date: 'shortDate'
                  }}</span>
                </div>
                <div class="notification-content">
                  {{ notification.content }}
                </div>
                <mat-divider class="notification-line"></mat-divider>
              </div>
            </div>
            <div *ngIf="notifications && notifications.length === 0">
              You have no notifications
            </div>
            <div *ngIf="!notifications">
              <app-small-loader></app-small-loader>
            </div>
          </div>
        </mat-menu>
      </button>
      <button
        mat-icon-button
        data-testid="user-icon-menu-button"
        class="user-button"
        [matMenuTriggerFor]="menu"
      >
        <div
          *ngIf="!authenticatedUser?.avatarUrl"
          class="icon"
          [class.active]="isActive"
        ></div>
        <img
          *ngIf="authenticatedUser?.avatarUrl"
          [src]="authenticatedUser?.avatarUrl"
          alt="avatar"
          [class.active]="isActive"
          draggable="false"
          (contextmenu)="onRightClick($event)"
        />
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          data-testid="my-profile-icon-button"
          routerLink="/my-profile-new"
          class="g-menu"
        >
          My profile
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          data-testid="logout-icon-button"
          (click)="logOut()"
          class="g-menu"
        >
          Logout
        </button>
      </mat-menu>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <span class="secure">Secure</span>
      <img src="assets/img/lock-closed.svg" alt="lock icon" class="lock" />
    </ng-container>
  </div>
</div>
