<div class="notification">
  <div fxLayoutAlign="start center" fxLayoutGap="7px">
    <div class="icon">
      <mat-icon>error_outline</mat-icon>
    </div>
    <span>{{ message }}</span>
  </div>

  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
