<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isLoading">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="12px">
  <!-- TOP BAR -->
  <div fxLayoutAlign="space-between top" fxLayoutGap="50px">
    <div *ngIf="doc">
      <h1>{{ doc.anotherFileName }}</h1>
      <h2>
        {{ doc.name }}
      </h2>
    </div>

    <div fxLayout fxLayoutGap="20px">
      <button
        mat-icon-button
        *ngIf="url"
        color="accent"
        [disabled]="isDownloading"
        [appButtonLoader]="isDownloading"
        (click)="downloadFile()"
      >
        <mat-icon class="material-icons-outlined">get_app</mat-icon>
      </button>

      <!-- Navigation section see later -->
      <div class="navigation-container">
        <button
          mat-icon-button
          class="previous"
          [disabled]="currentIndex === firstIndex || isLoading"
          (click)="goToPrevious()"
        >
          <mat-icon svgIcon="chevron-expanded"></mat-icon>
        </button>
        <button
          mat-icon-button
          class="next"
          [disabled]="currentIndex === lastIndex || isLoading"
          (click)="goToNext()"
        >
          <mat-icon svgIcon="chevron-expanded"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- CONTENT -->
  <div class="file-content" [class.pdf]="isPdf">
    <app-big-loader *ngIf="isGenerating"></app-big-loader>

    <!-- image -->
    <img *ngIf="url && !isPdf" [src]="url" />
    <!-- PDF -->
    <ngx-extended-pdf-viewer
      *ngIf="url && isPdf"
      [src]="url"
      [textLayer]="true"
      [showHandToolButton]="true"
      [showToolbar]="true"
      [showHighlightEditor]="false"
      [showDrawEditor]="false"
      [showOpenFileButton]="false"
      [showTextEditor]="false"
      [showStampEditor]="false"
      [showPropertiesButton]="false"
      [showScrollingButtons]="false"
      [showDownloadButton]="false"
      [showSpreadButton]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
  <div fxLayoutAlign="end center">
    <button
      mat-flat-button
      color="accent"
      mat-dialog-close
      [disabled]="isLoading"
    >
      Close
    </button>
  </div>
</mat-dialog-content>
