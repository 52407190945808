export const environment = {
  production: true,
  BACKEND_URL: '/api',
  SP_URL: 'https://app.shuftipro.com',
  ALLOWED_DOMAINS: [
    'dev-ecredo-client-web.s3.eu-central-1.amazonaws.com',
    'dtn5yz9rzht6n.cloudfront.net',
  ],
  DISABLE_CARDS: true,
  DISABLE_WALLET: true,
  DISABLE_SWIFT: false,
  DISABLE_PROFILE: false,
  DISABLE_UPGRADES: true,
  DISABLE_REGISTRATION: false,
  TIMEOUT: 29.5,
  DISABLE_STATIC_DATA_UPDATE: false,
  DISABLE_DOCUMENT_VIEW_DOWNLOAD: true,
};
