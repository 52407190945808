import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { StatementService } from 'src/app/statement/statement.service';
import { TransferService } from 'src/app/transfer/transfer.service';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './update-chids.component.html',
  styleUrls: ['./update-chids.component.scss'],
})
export class UpdateChidsComponent implements OnInit {
  private accountNums?: number[];

  constructor(
    private errorService: ErrorService,
    private transferService: TransferService,
    private statementService: StatementService,
    private http: HttpClient
  ) {}
  disabled: boolean = false;

  ngOnInit(): void {}

  updateChids(): void {
    this.transferService.updateAllChids().subscribe(
      () => {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 30000);
      },
      (error) => this.errorService.showErrorDialog(error.error.message)
    );
  }

  getAccountNumIds(): void {
    // this.statementService.getAllAccounts().subscribe(
    //   (accountNums) => {
    //     this.accountNums = accountNums;
    //   },
    //   () => {
    //     this.errorService.showErrorDialog();
    //   }
    // );
  }

  async onGenerateCsv() {
    if (this.accountNums)
      for (const acc of this.accountNums) {
        await (
          await this.getStatementsCsv(acc)
        ).subscribe(
          (csv) => {
            var element = document.createElement('a');
            element.setAttribute(
              'href',
              'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
            );
            element.setAttribute('download', 'statements_' + acc + '.csv');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          },
          (error) => {
            console.log('<<<' + acc);
            // this.errorService.showErrorDialog(error.error.message);
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
  }

  async getStatementsCsv(acc: number): Promise<Observable<string>> {
    // replace with 'https://prod.ecredo.com/api/financial/accountStatement.csv';
    const url = `${environment.BACKEND_URL}/financial/accountStatement.csv`;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http.post(
      url,
      JSON.stringify({
        numAccountId: Number(acc),
        dtStartDate: '2018-01-01',
        dtEndDate: new Date().toString(),
        page: 1,
        limit: 5000,
        orderAscending: false,
        searchText: undefined,
        orderField: undefined,
        currency: undefined, // enter wallet code e.g. "GBP" or undefined
      }),
      { responseType: 'text', headers: headers }
    );
  }
}
