<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="20px">
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="user-ip-login"
    routerLinkActive="active"
  >
    IP Login
  </button>
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="active-sessions"
    routerLinkActive="active"
  >
    Active Sessions
  </button>
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="expiring-documents"
    routerLinkActive="active"
  >
    Expiring documents
  </button>
</div>
<router-outlet></router-outlet>
