import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Subscription, timer } from 'rxjs';

export interface SlidingNotificationData {
  message: string;
}

@Component({
  selector: 'sliding-notification',
  templateUrl: './sliding-notification.component.html',
  styleUrls: ['./sliding-notification.component.scss'],
})
export class SlidingNotificationComponent implements OnInit, OnDestroy {
  message: string;
  private notificationTimer: Subscription | null = null;

  constructor(
    private snackBarRef: MatSnackBarRef<SlidingNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SlidingNotificationData
  ) {
    this.message = data.message;
  }

  ngOnInit() {
    // show the notification for 30 seconds
    this.notificationTimer = timer(30000).subscribe(() =>
      this.snackBarRef.dismiss()
    );
  }

  ngOnDestroy() {
    // cleanup the timer subscription when the component is destroyed
    this.notificationTimer?.unsubscribe();
  }

  close(): void {
    this.notificationTimer?.unsubscribe();
    this.snackBarRef.dismiss();
  }
}
