<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isLoading">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="12px">
  <!-- TOP BAR -->
  <div fxLayoutAlign="space-between top" fxLayoutGap="50px">
    <div>
      <h1>{{ data.headerText }}</h1>
      <h2>
        {{
          doc.anotherFileName ||
            (showProofOfOperatingAddress &&
            doc.type === AppDocumentType.PROOF_OF_ADDRESS
              ? 'Proof of Operating Address'
              : getDocumentName(doc.type))
        }}
      </h2>
    </div>

    <div fxLayout fxLayoutGap="20px">
      <!-- Accept/Reject section -->
      <div class="state-container">
        <div fxLayout fxLayoutAlign="end center" fxLayoutGap="20px">
          <button
            mat-stroked-button
            color="accent"
            class="g-medium"
            [disabled]="doc.isAccepted || isLoading || currentControl.disabled"
            (click)="saveAcceptation(true, commentInput)"
          >
            {{ doc.isAccepted ? 'Accepted' : 'Accept' }}
          </button>
          <button
            mat-stroked-button
            color="accent"
            class="g-medium"
            [disabled]="
              acceptedDocumentTypes.includes(doc.type) ||
              doc.isAccepted === false ||
              isLoading ||
              currentControl.disabled
            "
            (click)="saveAcceptation(false, commentInput)"
          >
            {{ doc.isAccepted === false ? 'Rejected' : 'Reject' }}
          </button>
        </div>
        <div
          fxLayout="column"
          [fxHide]="doc.isAccepted !== false"
          class="explain-reason"
        >
          <label>Explain the reason of rejection</label>
          <mat-form-field>
            <textarea
              matInput
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="3"
              [cdkAutosizeMaxRows]="3"
              [disabled]="isLoading || currentControl.disabled"
              [ngModel]="doc.rejectionReason"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="saveRejectionReason($event)"
              required
              #commentInput="matInput"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <button
        mat-icon-button
        *ngIf="url"
        color="accent"
        [disabled]="isDownloading"
        [appButtonLoader]="isDownloading"
        (click)="downloadFile()"
      >
        <mat-icon class="material-icons-outlined">get_app</mat-icon>
      </button>

      <!-- Navigation section -->
      <div class="navigation-container">
        <button
          mat-icon-button
          class="previous"
          [disabled]="currentIndex === firstIndex || isLoading"
          (click)="goToPrevious(commentInput)"
        >
          <mat-icon svgIcon="chevron-expanded"></mat-icon>
        </button>
        <button
          mat-icon-button
          class="next"
          [disabled]="currentIndex === lastIndex || isLoading"
          (click)="goToNext(commentInput)"
        >
          <mat-icon svgIcon="chevron-expanded"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!-- CONTENT -->
  <div class="file-content" [class.pdf]="isPdf">
    <app-big-loader *ngIf="isGenerating"></app-big-loader>
    <!-- reasonWhyNotProvided -->
    <div *ngIf="doc.reasonWhyNotUploaded">{{ doc.reasonWhyNotUploaded }}</div>
    <!-- image -->
    <img *ngIf="url && !isPdf && !isVideo" [src]="url" />
    <!-- video -->
    <video *ngIf="url && !isPdf && isVideo" controls autoplay>
      <source [src]="url" type="video/mp4" />
    </video>
    <!-- PDF -->
    <ngx-extended-pdf-viewer
      *ngIf="url && isPdf"
      [src]="url"
      [textLayer]="true"
      [showHandToolButton]="true"
      [showToolbar]="true"
      [showHighlightEditor]="false"
      [showDrawEditor]="false"
      [showOpenFileButton]="false"
      [showTextEditor]="false"
      [showStampEditor]="false"
      [showPropertiesButton]="false"
      [showScrollingButtons]="false"
      [showDownloadButton]="false"
      [showSpreadButton]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
  <div fxLayoutAlign="end center">
    <button
      mat-flat-button
      color="accent"
      mat-dialog-close
      [disabled]="isLoading"
    >
      Close
    </button>
  </div>
</mat-dialog-content>
