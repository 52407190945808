<mat-card>
  <div class="top-container">
    <div
      class="filters-container"
      fxLayout
      fxLayoutGap="30px"
      fxLayoutAlign="start center"
      [formGroup]="filtersGroup"
    >
      <div fxLayout="column">
        <label for="search">Search</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="search" name="search" />
          <mat-icon matIconSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Date range</label>
        <mat-form-field appearance="outline" class="date">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="endDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <mat-checkbox color="accent" formControlName="individuals"
        >Individuals</mat-checkbox
      >
      <mat-checkbox color="accent" formControlName="businesses"
        >Businesses</mat-checkbox
      >
      <mat-checkbox color="accent" formControlName="admins"
        >Admins</mat-checkbox
      >
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="30px">
      <div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="onGenerateCsv()"
          [appButtonLoader]="isLoadingCsv"
        >
          Download CSV
        </button>
      </div>
    </div>
  </div>

  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      [matSortActive]="orderField"
      [matSortDirection]="sortDirection"
      matSortDisableClear
    >
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.isFirstUserEntry">
            {{ row.userId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="names">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="firstName">First name</div>
          <div mat-sort-header="lastName">Last name</div>
        </th>
        <td data-testid="name-cell" mat-cell *matCellDef="let row">
          <div *ngIf="row.isFirstUserEntry">
            <div>{{ row.firstName }}</div>
            <div>{{ row.lastName }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Business name</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.isFirstUserEntry">
            {{ row.companyName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.isFirstUserEntry">{{ row.userName }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfActiveSessions">
        <th mat-header-cell *matHeaderCellDef>Active Sessions</th>
        <td mat-cell *matCellDef="let row">
          <span class="session-number" *ngIf="row.isFirstUserEntry">
            {{ row.numberOfActiveSessions }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="ipAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IP Address</th>
        <td mat-cell *matCellDef="let row">{{ row.ipAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="userAgent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Agent</th>
        <td mat-cell *matCellDef="let row">{{ row.userAgent }}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateCreated | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expiryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
        <td mat-cell *matCellDef="let row">
          {{ row.expiryDate | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</mat-card>
