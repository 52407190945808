import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivationFileModel } from '../admin/actions/activation/models/activation-file.model';
import { MassPayment } from './mass-payments/models/mass-payment.model';
import { FavouriteTransfer } from './shared/models/favourite-transfer.model';
import { Fees } from './shared/models/fees.model';
import { RecurringTransfer } from './shared/models/recurring-transfer.model';
import { TopUp } from './shared/models/top-up.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from '../shared/services/encryption.service';
import {
  MassPaymentModel,
  MassPaymentModelWithOtp,
} from './mass-payments/models/mass-payment-execute.model';
import { GetPriceList } from './shared/models/get-price-list.model';
import { ValidateExecutionDate } from './shared/models/validate-execution-date.model';
import { Transfer, TransferWithOtp } from './shared/models/transfer.model';
import { E2eBeneficiaryDetails } from './shared/models/e2e-beneficiary-details.model';
import { Beneficiary } from './shared/models/beneficiary.model';
import { Currency } from './shared/models/currency.model';
import { PriceList } from './shared/models/price-list.model';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  baseFinancialUrl = `${environment.BACKEND_URL}/financial`;
  baseRecurringUrl = `${environment.BACKEND_URL}/recurring`;

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  async getIbanAccountByPhoneNumber(
    phoneNumber: string
  ): Promise<E2eBeneficiaryDetails> {
    const url = `${this.baseFinancialUrl}/iban-by-phone-number`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(phoneNumber);
    return this.http.post<E2eBeneficiaryDetails>(url, cipherModel).toPromise();
  }

  async getPhoneNumberByIbanAccount(
    accountOwnerGuid: string,
    favouriteId: number
  ): Promise<{ phoneNumber: string }> {
    const url = `${this.baseFinancialUrl}/phone-number-by-iban`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ accountOwnerGuid, favouriteId });
    return this.http
      .post<{ phoneNumber: string }>(url, cipherModel)
      .toPromise();
  }

  getFees(): Observable<Fees> {
    const url = `${environment.BACKEND_URL}/fees`;
    return this.http.get<Fees>(url);
  }

  getFXQuote(
    fromCurr: string,
    toCurr: string,
    amount: number
  ): Observable<{ amount: string; rate: string }> {
    const url = `${this.baseFinancialUrl}/getFXQuote`;

    return this.http.post<{ amount: string; rate: string }>(url, {
      fromCurr,
      toCurr,
      amount,
    });
  }

  getFXBuy(fromCurr: string, toCurr: string, amount: number): Observable<void> {
    const url = `${this.baseFinancialUrl}/getFXBuy`;

    return this.http.post<void>(url, {
      fromCurr,
      toCurr,
      amount,
    });
  }

  async validateIban(iban: string): Promise<any> {
    const url = `${this.baseFinancialUrl}/validate-iban`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(iban);
    return this.http.post<any>(url, cipherModel).toPromise();
  }

  async validateBic(bic: string): Promise<any> {
    const url = `${this.baseFinancialUrl}/validate-bic`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(bic);
    return this.http.post<any>(url, cipherModel).toPromise();
  }

  async getCurrencyCodeList(
    accountCode: string
  ): Promise<Observable<Currency[]>> {
    const url = `${this.baseFinancialUrl}/currency-code-list`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(accountCode);
    return this.http.post<Currency[]>(url, cipherModel);
  }

  async validateExecutionDate(model: ValidateExecutionDate): Promise<any> {
    const url = `${this.baseFinancialUrl}/validate-execution-date`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(model);
    return this.http.post<any>(url, cipherModel);
  }

  async getPriceList(
    getPricelist: GetPriceList
  ): Promise<Observable<PriceList[]>> {
    const url = `${this.baseFinancialUrl}/price-list`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(getPricelist);
    return this.http.post<PriceList[]>(url, cipherModel);
  }

  async getBeneficiaryList(
    accountOwnerGuid: string
  ): Promise<Observable<Beneficiary[]>> {
    const url = `${this.baseFinancialUrl}/beneficiary-list`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(accountOwnerGuid);
    return this.http.post<Beneficiary[]>(url, cipherModel);
  }

  async getFavouriteTransfers(
    accountOwnerGuid: string
  ): Promise<Observable<FavouriteTransfer[]>> {
    const url = `${this.baseFinancialUrl}/favourite-transfers`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(accountOwnerGuid);
    return this.http.post<FavouriteTransfer[]>(url, cipherModel);
  }

  async saveFavouriteTransfer(transfer: Transfer): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/save-favourite-transfer`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(transfer);
    return this.http.post<void>(url, cipherModel);
  }

  async deleteFavouriteTransfer(guid: string): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/delete-favourite-transfer`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(guid);
    return this.http.post<void>(url, cipherModel);
  }

  // OTPs
  async sendE2enowOtp(transfer: Transfer): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/e2enow/send-otp`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(transfer);
    return this.http.post<void>(url, cipherModel);
  }

  async sendSepaOtp(body: Transfer): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/sepaTransfer/send-otp`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  async sendSwiftOtp(body: Transfer): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/swiftTransfer/send-otp`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  // validate and create transfer
  async createTransfer(
    body: Transfer
  ): Promise<Observable<{ otpVerifyToken: string }>> {
    const url = `${this.baseFinancialUrl}/create-transfer`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<{ otpVerifyToken: string }>(url, cipherModel);
  }

  // TRANSFERS
  async transferInBetween(
    transfer: TransferWithOtp
  ): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/inbetween`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(transfer);
    return this.http.post<void>(url, cipherModel);
  }

  async transferE2enow(
    transferWithOtp: TransferWithOtp
  ): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/e2enow`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(transferWithOtp);
    return this.http.post<void>(url, cipherModel);
  }

  async transferSepa(body: TransferWithOtp): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/sepaTransfer`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  async transferSwift(body: TransferWithOtp): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/swiftTransfer`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  // send sms activation code
  activationFileUpload(file: File): Observable<ActivationFileModel> {
    const url = `${this.baseFinancialUrl}/activationFileUpload`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<ActivationFileModel>(url, formData);
  }

  sendActivationCodeExecute(
    body: ActivationFileModel,
    code: string
  ): Observable<void> {
    const url = `${this.baseFinancialUrl}/sendActivationCodeExecute?code=${code}`;
    return this.http.post<void>(url, body);
  }

  updateAllChids(): Observable<void> {
    const url = `${this.baseFinancialUrl}/updateAllChids`;
    return this.http.get<void>(url);
  }

  // mass payment
  massPaymentUpload(file: File): Observable<MassPayment> {
    const url = `${this.baseFinancialUrl}/masspaymentupload`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<MassPayment>(url, formData);
  }

  async sendMassPaymentOtp(body: MassPaymentModel): Promise<Observable<void>> {
    const url = `${this.baseFinancialUrl}/massTransfer/send-otp`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  massPaymentExecute(body: MassPaymentModelWithOtp): Observable<void> {
    const url = `${this.baseFinancialUrl}/masspaymentexecute`;
    return this.http.post<void>(url, body);
  }

  //top up by debit card
  topUp(body: TopUp): Observable<{ url: string }> {
    const url = `${this.baseFinancialUrl}/topup`;
    return this.http.post<{ url: string }>(url, body);
  }

  getRecurringTransfers(rtSearch?: string): Observable<RecurringTransfer[]> {
    const url = `${this.baseRecurringUrl}/get-recurring`;
    const params = rtSearch ? new HttpParams().set('rtSearch', rtSearch) : {};
    return this.http.get<RecurringTransfer[]>(url, { params });
  }

  stopRecurringTransfer(rtId: number): Observable<void> {
    const url = `${this.baseRecurringUrl}/stop-recurring?rtId=${rtId}`;
    return this.http.post<void>(url, null);
  }
}
