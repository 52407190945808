import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { saveAs } from 'file-saver';
import { switchMap } from 'rxjs/operators';
import { OnboardingAdminDocument } from 'src/app/onboarding-new/models/document.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  acceptedDocumentTypes,
  getDocumentName,
} from 'src/app/shared/helpers/various-helpers.helper';
import { FilesService } from 'src/app/shared/services/files.service';
import { UsersService } from '../../../users.service';
import {
  AdminConversationDialog,
  AdminConversationDialogNewComponent,
} from '../../../../../onboarding-new/shared/admin-conversation-dialog/admin-conversation-dialog.component';
import { AdminFileDialogData } from '../../admin-file-dialog/admin-file-dialog-data.model';
import { AdminFileDialogComponent } from '../../admin-file-dialog/admin-file-dialog.component';
import { AppDocumentType } from 'src/app/onboarding-new/models/document-type.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-document',
  templateUrl: './admin-document.component.html',
  styleUrls: ['./admin-document.component.scss'],
})
export class AdminDocumentComponent implements ControlValueAccessor {
  @Input() fileDialogHeader!: string;
  @Input() userId!: number;
  @Input() companyDirectorId?: number;
  @Input() showProofOfOperatingAddress: boolean = false;

  getDocumentName = getDocumentName;
  acceptedDocumentTypes = acceptedDocumentTypes;
  AppDocumentType = AppDocumentType;

  value!: OnboardingAdminDocument;
  onChange = (val: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  isLoading: boolean = false;
  isDownloading: boolean = false;
  environment = environment;

  constructor(
    private ngControl: NgControl,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private filesService: FilesService,
    private usersService: UsersService
  ) {
    this.ngControl.valueAccessor = this;
  }

  downloadFile(): void {
    this.isDownloading = true;
    this.filesService
      .generateUrl(this.value.location)
      .pipe(switchMap(({ url }) => this.filesService.downloadFile(url)))
      .subscribe(
        (blob) => {
          saveAs(blob, this.value.name);
          this.isDownloading = false;
        },
        () => {
          this.isDownloading = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  openWholeConversation(): void {
    this.dialog.open<
      AdminConversationDialogNewComponent,
      AdminConversationDialog
    >(AdminConversationDialogNewComponent, {
      width: '700px',
      panelClass: 'dialog-with-close-button',
      data: {
        isAdmin: true,
        comments: this.value.comments,
        headerText:
          this.value.anotherFileName ||
          (this.showProofOfOperatingAddress &&
          this.value.type === AppDocumentType.PROOF_OF_ADDRESS
            ? 'Proof of Operating Address'
            : getDocumentName(this.value.type)),
      },
    });
  }

  saveAcceptation(
    accepted: boolean,
    group: NgModel,
    commentInput: MatInput
  ): void {
    group.control.markAsTouched(); // there's an error without it
    commentInput.ngControl.control?.markAsUntouched(); // clears validation for comment textarea
    this.isLoading = true;
    this.ngControl.control?.markAsPending();
    const saveObs = this.companyDirectorId
      ? this.usersService.changeDirectorDocumentStatus(
          this.userId,
          this.companyDirectorId,
          this.value.id,
          accepted
        )
      : this.usersService.changeDocumentStatus(
          this.userId,
          this.value.id,
          accepted
        );

    saveObs.subscribe(
      (doc) => {
        this.value = doc;
        if (!accepted) {
          setTimeout(() => commentInput.focus()); // automatic focus of comment textarea
        }
        this.onChange(this.value);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorService.showErrorDialog(error.error.message);
        this.onChange(this.value); // without it, it disables the buttons
      }
    );
  }

  saveRejectionReason(content: string): void {
    this.isLoading = true;
    this.ngControl.control?.markAsPending();
    const saveObs = this.companyDirectorId
      ? this.usersService.addDirectorDocumentComment(
          this.userId,
          this.value.id,
          this.companyDirectorId,
          content
        )
      : this.usersService.addDocumentComment(
          this.userId,
          this.value.id,
          content
        );

    saveObs.subscribe(
      (doc) => {
        this.value = doc;
        this.onChange(this.value);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  openFileDialog(): void {
    if (environment.DISABLE_DOCUMENT_VIEW_DOWNLOAD) {
      return;
    }
    this.dialog.open<AdminFileDialogComponent, AdminFileDialogData>(
      AdminFileDialogComponent,
      {
        minWidth: '1000px',
        disableClose: true,
        panelClass: 'dialog-with-close-button',
        data: {
          headerText: this.fileDialogHeader,
          companyDirectorId: this.companyDirectorId,
          userId: this.userId,
          control: this.ngControl.control!,
          showProofOfOperatingAddress: this.showProofOfOperatingAddress,
        },
      }
    );
  }

  // Methods below are needed for ControlValueAccessor
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  writeValue(val: OnboardingAdminDocument) {
    this.value = val;
  }
}
