import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { switchMap } from 'rxjs/operators';
import { AppDocument } from 'src/app/onboarding-new/models/document.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { FilesService } from 'src/app/shared/services/files.service';

export interface AdminNoteFileDialogData {
  control: AbstractControl;
  documentIndex: number;
}

@Component({
  templateUrl: './admin-note-file-dialog.component.html',
  styleUrls: ['./admin-note-file-dialog.component.scss'],
})
export class AdminNoteFileDialogComponent implements OnInit {
  url: string = '';
  isPdf: boolean = false;
  doc!: AppDocument;
  parentFormArray!: FormArray;

  currentIndex: number = 0;
  firstIndex: number = 0;
  lastIndex: number = 0;
  isLoading: boolean = false;
  isGenerating: boolean = false;
  isDownloading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdminNoteFileDialogData,
    private errorService: ErrorService,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.parentFormArray = this.data.control as FormArray;
    this.currentIndex = this.data.documentIndex;
    this.lastIndex = this.parentFormArray.length - 1;
    this.setCurrentDoc(this.currentIndex);
  }

  setCurrentDoc(index: number): void {
    this.currentIndex = index;
    this.doc = this.parentFormArray.at(index).value.doc;
    this.isDownloading = false; // clear downloading status when document changed

    if (this.doc.location) {
      this.isPdf = this.doc.location?.split('.').pop() === 'pdf';
      this.generateUrl();
    } else {
      this.isPdf = false;
      this.url = '';
    }
  }

  downloadFile(): void {
    this.isDownloading = true;
    this.filesService
      .generateUrl(this.doc.location)
      .pipe(switchMap(({ url }) => this.filesService.downloadFile(url)))
      .subscribe(
        (blob) => {
          saveAs(blob, this.doc.name);
          this.isDownloading = false;
        },
        () => {
          this.isDownloading = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  generateUrl(): void {
    this.isLoading = true;
    this.isGenerating = true;

    this.url = '';
    this.filesService.generateUrl(this.doc.location).subscribe(
      ({ url }) => {
        this.url = url;
        this.isLoading = false;
        this.isGenerating = false;
      },
      () => {
        this.isLoading = false;
        this.isGenerating = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  goToNext(): void {
    this.setCurrentDoc(this.currentIndex + 1);
  }

  goToPrevious(): void {
    this.setCurrentDoc(this.currentIndex - 1);
  }

  get currentControl(): AbstractControl {
    return this.parentFormArray.at(this.currentIndex);
  }
}
