import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { ServerSentEventType } from 'src/app/shared/models/server-sent-event-type.enum';
import { UserEventsService } from 'src/app/shared/services/user-events.service';
import { environment } from 'src/environments/environment';
import { TransferService } from '../transfer.service';
import { MassPaymentsSnackbarComponent } from './mass-payments-snackbar/mass-payments-snackbar.component';
import { MassPayment } from './models/mass-payment.model';
import { HttpClient } from '@angular/common/http';
import {
  MassPaymentModel,
  MassPaymentModelWithOtp,
} from './models/mass-payment-execute.model';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  COUNTDOWN_IN_SECONDS,
  MAX_ATTEMPTS,
  MAX_SMS_RESENDS,
} from 'src/app/shared/helpers/various-helpers.helper';

export enum MassPaymentState {
  initial,
  fileProcessing,
  fileErroring,
  fileUploaded,
  confirmation,
  transfersInProgress,
  transfersFinished,
  retryInProgress,
  retryFinished,
}

@Component({
  templateUrl: './mass-payments.component.html',
  styleUrls: ['./mass-payments.component.scss'],
})
export class MassPaymentsComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  state: MassPaymentState = MassPaymentState.initial;

  // first view variables
  fromAccount?: AccountDetails;
  uploadError?: string;
  massPayment?: MassPayment;
  $breakpointObserver?: Observable<boolean>;

  //second view variables
  statusMap: Map<string, boolean> = new Map<string, boolean>(); // <transactionId, status>
  failedTransfers: string[] = [];
  successCount: number = 0;
  failureCount: number = 0;
  retrySuccessCount: number = 0;
  retryFailureCount: number = 0;

  MassPaymentState = MassPaymentState;

  private initialViewStatesSet = new Set([
    MassPaymentState.initial,
    MassPaymentState.fileProcessing,
    MassPaymentState.fileErroring,
    MassPaymentState.fileUploaded,
  ]);
  private statesWithTransfersStatusSet = new Set([
    MassPaymentState.transfersFinished,
    MassPaymentState.retryInProgress,
    MassPaymentState.retryFinished,
  ]);

  private eventsSub?: Subscription;
  otpControl = this.fb.control(null, Validators.required);
  failedOtpAttempts: number = 0;
  resendSmsAttempts: number = 0;
  countdown: number = COUNTDOWN_IN_SECONDS;
  isSendAgainDisabled = false;
  restartProcess = false;
  smsSentAgain = false;
  isSendingAgain = false;

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private transferService: TransferService,
    private userEventsService: UserEventsService,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        this.fromAccount = accounts.iban;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((state) => state.breakpoints[Breakpoints.XSmall]));
  }

  ngOnDestroy(): void {
    this.eventsSub?.unsubscribe();
  }

  // Functions are listed in the order of states:

  onUpload(uploadObs: Observable<MassPayment>): void {
    this.restartProcess = false; // remove message when user interacts with form
    this.failedOtpAttempts = 0; // reset counters on upload
    this.resendSmsAttempts = 0;
    this.state = MassPaymentState.fileProcessing;
    this.uploadError = '';
    uploadObs.subscribe(
      (massPayment) => {
        this.state = MassPaymentState.fileUploaded;
        this.massPayment = massPayment;
      },
      (error) => {
        this.state = MassPaymentState.fileErroring;
        if (error.status === 400) {
          this.uploadError = error.error.message;
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  async goToConfirmationStep(isSmsResend: boolean): Promise<void> {
    this.smsSentAgain = false;
    this.isSendingAgain = true;
    const body: MassPaymentModel = {
      transactionCode: this.massPayment?.transactionCode,
      totalAmount: this.massPayment?.totalAmount,
    };

    (await this.transferService.sendMassPaymentOtp(body)).subscribe(
      () => {
        this.state = MassPaymentState.confirmation;
        this.isSendingAgain = false;
        this.smsSentAgain = isSmsResend;
      },
      () => {
        this.isSendingAgain = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  reSendSms() {
    if (this.resendSmsAttempts >= MAX_SMS_RESENDS) {
      this.cancel(true); // force user to restart process if many sms resends
    } else {
      this.startCountdown();
      this.resendSmsAttempts += 1;
      this.goToConfirmationStep(true);
    }
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  cancel(restartProcess: boolean): void {
    this.state = MassPaymentState.initial;
    this.otpControl.reset();
    this.restartProcess = restartProcess;
  }

  confirm(): void {
    this.runTransactions(false);
  }

  goToTransfersFinishedStep(): void {
    this.state = MassPaymentState.transfersFinished;
  }

  downloadReport(): void {
    // get current status of each payment
    const massPayments = {
      ...this.massPayment,
      lines: this.massPayment?.lines.map((mp) => {
        return {
          ...mp,
          status: this.statusMap.get(mp.transactionId),
        };
      }),
    };
    const url = `${environment.BACKEND_URL}/financial/masspaymentreport`;
    this.httpClient
      .post(url, massPayments, {
        observe: 'body',
        responseType: 'arraybuffer',
      })
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'masspaymentreport.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        (error) => this.errorService.showErrorDialog(error.error.message)
      );
  }

  retry(): void {
    this.state = MassPaymentState.retryInProgress;
    this.runTransactions(true);
  }

  goToRetryFinishedStep(): void {
    this.state = MassPaymentState.retryFinished;
    this.snackBar.openFromComponent(MassPaymentsSnackbarComponent, {
      data: {
        successCount: this.retrySuccessCount,
        failureCount: this.retryFailureCount,
      },
      panelClass: 'g-snackbar',
    });
  }

  private runTransactions(isRetry: boolean): void {
    const eventsCount = isRetry
      ? this.failureCount
      : this.massPayment!.lines.length;

    // Events subscription
    this.eventsSub?.unsubscribe();
    this.eventsSub = this.userEventsService.userEventsObservable
      .pipe(
        map((ev) => JSON.parse(ev)),
        filter(
          (ev) =>
            ev.type === ServerSentEventType.MASS_PAYMENT_EXECUTION &&
            ev.massPaymentsId === this.massPayment?.transactionCode
        ),
        take(eventsCount)
      )
      .subscribe(
        (ev) => {
          this.ngZone.run(() => {
            if (isRetry) {
              if (ev.transactionStatus) {
                this.retrySuccessCount++;
                this.successCount++;
                this.failureCount--;
              } else {
                this.retryFailureCount++;
              }
            } else {
              if (ev.transactionStatus) {
                this.successCount++;
              } else {
                this.failureCount++;
                this.failedTransfers.push(ev.transactionId);
              }
            }
            this.statusMap = this.statusMap.set(
              ev.transactionId,
              ev.transactionStatus
            );
          });
        },
        () => this.ngZone.run(() => this.errorService.showErrorDialog()),
        () =>
          this.ngZone.run(() =>
            isRetry
              ? this.goToRetryFinishedStep()
              : this.goToTransfersFinishedStep()
          )
      );

    const body: MassPaymentModelWithOtp = {
      transactionCode: this.massPayment!.transactionCode,
      otp: this.otpControl.value,
      failedTransfers: this.failedTransfers,
      transfers: this.massPayment!.lines.map((mp) => {
        return {
          otpVerifyToken: mp.otpVerifyToken,
          transactionId: mp.transactionId,
        };
      }),
    };

    this.transferService.massPaymentExecute(body).subscribe(
      () => {
        this.state = MassPaymentState.transfersInProgress;
      },
      (error) => {
        if (error.status === 400) {
          this.failedOtpAttempts += 1;
          this.otpControl.setErrors({ invalidOtp: true });
          if (this.failedOtpAttempts >= MAX_ATTEMPTS) {
            this.cancel(true); // force user to restart process if too many failed otps
          }
        } else {
          this.errorService.showErrorDialog(error.error.message);
        }
      }
    );
  }

  get isInitialView(): boolean {
    return this.initialViewStatesSet.has(this.state);
  }
  get isShowingTransfersStatus(): boolean {
    return this.statesWithTransfersStatusSet.has(this.state);
  }
}
