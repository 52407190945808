import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Country } from 'src/app/shared/models/country.model';
import { Program } from 'src/app/login/models/program.enum';
import { UserState } from 'src/app/login/models/user-state.enum';
import { CompanyDirectorWithDocuments } from 'src/app/onboarding-new/models/company-director.model';
import { adminDocumentValidator } from 'src/app/shared/admin-document.validator';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { convertUserState } from 'src/app/shared/helpers/various-helpers.helper';
import { OnboardingAdminData } from '../../models/onboarding-admin-data.model';
import { OnboardingFieldId } from '../../models/onboarding-field-id.enum';
import { UsersService } from '../../users.service';
import { AdminNoteDialogData } from '../admin-note-dialog/admin-note-dialog-data.model';
import { AdminNoteDialogComponent } from '../admin-note-dialog/admin-note-dialog.component';
import {
  generateAdminAccountForm,
  generateAdminCloseLinkCombinationsForm,
  generateAdminCloseLinkForm,
  generateAdminCorporateForm,
  generateAdminCounterpartyForm,
  generateAdminDirectorForm,
  generateAdminGroupMemberForm,
  generateAdminPersonalForm,
} from './admin-form.helper';
import {
  VerifyClientDialogComponent,
  VerifyClientDialogData,
} from './verify-client-dialog/verify-client-dialog.component';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { HttpClient } from '@angular/common/http';
import {
  ClientDecisionDialogComponent,
  ClientDecisionDialogData,
} from './client-decision-dialog/client-decision-dialog.component';
import { RegisteredUsersService } from '../../registered-users/registered-users.service';

enum ViewState {
  INITIAL,
  NORMAL,
  VIEW_ONLY,
}

@Component({
  templateUrl: './ongoing-registration-user.component.html',
  styleUrls: ['./ongoing-registration-user.component.scss'],
})
export class OngoingRegistrationUserNewComponent implements OnInit {
  environment = environment;
  state: ViewState = ViewState.INITIAL;
  isLoading: boolean = false;
  isAccepting: boolean = false;
  isReviewing: boolean = false;
  isRetriggering: boolean = false;
  isChangingSuggestionStatus: boolean = false;
  isLockingOrUnlockingUser: boolean = false;

  userId!: number;
  countries: Country[] = [];
  data: OnboardingAdminData | undefined;
  directors: CompanyDirectorWithDocuments[] = [];
  isSameAddress?: boolean;
  generalComment?: string | null;
  userProgram?: Program;

  documentsArray: FormArray = this.fb.array([]);
  directorsArray: FormArray = this.fb.array([]);
  personalGroup?: FormGroup;
  corporateGroup?: FormGroup;
  accountGroup: FormGroup = this.fb.group({
    accountForm: this.fb.array([]),
  });
  counterpartyGroup: FormGroup = this.fb.group({
    counterpartyForm: this.fb.array([]),
  });
  directorGroup: FormGroup = this.fb.group({
    directorForm: this.fb.array([]),
  });
  closeLinkGroup: FormGroup = this.fb.group({
    closeLinkForm: this.fb.array([]),
  });
  directorCloseLinkGroup: FormGroup = this.fb.group({
    directorCloseLinkForm: this.fb.array([]),
  });
  groupMemberGroup: FormGroup = this.fb.group({
    groupMemberForm: this.fb.array([]),
  });
  generalCommentControl: FormControl = this.fb.control('');

  programs = Program;
  ViewState = ViewState;
  convertUserState = convertUserState;
  UserState = UserState;
  Program = Program;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private usersService: UsersService,
    private httpClient: HttpClient,
    private registeredUsersService: RegisteredUsersService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  addNote(): void {
    const data: AdminNoteDialogData = {
      id: this.userId,
      headerText: this.headerText,
      program: this.data!.program,
      dateCreated: this.data!.dateCreated,
      email: this.data!.email,
      cellPhoneNumber: this.data!.cellPhoneNumber,
      state: this.data!.state,
    };
    this.dialog
      .open<AdminNoteDialogComponent, AdminNoteDialogData, number>(
        AdminNoteDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '1000px',
          disableClose: true,
          data,
        }
      )
      .beforeClosed()
      .subscribe((noteCount) => (this.data!.noteCount = noteCount!));
  }

  downloadAllDocuments(): void {
    const url = `${environment.BACKEND_URL}/admin/users/${this.userId}/documents`;
    this.httpClient
      .get(url, { observe: 'body', responseType: 'arraybuffer' })
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'documents.zip';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        (error) => this.errorService.showErrorDialog(error.error.message)
      );
  }

  lockOrUnlockUser(): void {
    this.isLockingOrUnlockingUser = true;
    this.registeredUsersService.lockOrUnlockUser(this.userId).subscribe(
      () => {
        this.isLockingOrUnlockingUser = false;
        this.loadData();
      },
      (error) => {
        this.isLockingOrUnlockingUser = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  allowRetriggerStakeholderScoring() {
    const stakeholders = this.data?.companyDirectors?.filter(
      (d) =>
        d.isLegalPerson ||
        (!d.isLegalPerson &&
          !(
            d.isAuthorizedPerson &&
            !d.isDirector &&
            !d.isShareholder &&
            !d.isUBO
          ))
    ); // filter out authorized contact persons
    return stakeholders?.every((d) => d.onboardingVerificationDone);
  }

  retriggerStakeholderScoring(): void {
    this.isRetriggering = true;
    this.usersService.retriggerStakeholderScoring(this.userId).subscribe(
      () => {
        this.isRetriggering = false;
        this.loadData();
      },
      (error) => {
        this.isRetriggering = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  saveGeneralComment(content: string): void {
    const fieldId = this.personalGroup
      ? OnboardingFieldId.PERSONAL
      : OnboardingFieldId.BUSINESS;

    this.generalCommentControl.markAsPending();
    this.usersService
      .addOrModifyFormCommment(this.userId, fieldId, content)
      .subscribe(
        () => this.generalCommentControl.updateValueAndValidity(), // clears pending state
        (error) => {
          this.errorService.showErrorDialog(error.error.message);
          this.generalCommentControl.updateValueAndValidity(); // without it, it disables the buttons
        }
      );
  }

  // accept all unrejected documents
  acceptIndeterminates(): void {
    this.isAccepting = true;
    this.usersService.acceptIndeterminates(this.userId).subscribe(
      () => {
        this.isAccepting = false;
        this.loadData();
      },
      (error) => {
        this.isAccepting = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  // returns application to user so that changes are made
  returnApplication(): void {
    this.isReviewing = true;
    this.usersService.returnApplication(this.userId).subscribe(
      () => {
        this.isReviewing = false;
        this.router.navigate(['..']); // returns back to ongoing registrations table
      },
      (error) => {
        this.isReviewing = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  verifyClient(): void {
    this.dialog
      .open<VerifyClientDialogComponent, VerifyClientDialogData, boolean>(
        VerifyClientDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '720px',
          disableClose: true,
          data: {
            userId: this.userId,
            userName: this.headerText,
          },
        }
      )
      .beforeClosed()
      .pipe(filter((isVerified) => !!isVerified))
      .subscribe(() => {
        this.router.navigate(['..']); // returns back to ongoing registrations table
      });
  }

  getAcceptedCount(controls: FormControl[]): number {
    return controls.filter((item) => item.value.isAccepted).length;
  }

  getRejectedCount(controls: FormControl[]): number {
    return controls.filter((item) => item.value.isAccepted === false).length;
  }

  getIndeterminateCount(controls: FormControl[]): number {
    return controls.filter((item) => item.hasError('indeterminate')).length;
  }

  getMissingCommentCount(controls: FormControl[]): number {
    return controls.filter((item) => item.hasError('missingComment')).length;
  }

  get headerText(): string {
    return this.data?.personalForm
      ? `${this.data?.personalForm.firstName} ${this.data?.personalForm.lastName}`
      : `${this.data?.corporateForm?.businessName}`;
  }

  // documents
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
  // directors
  get directorsDocControls(): FormControl[] {
    let controls: FormControl[] = [];
    this.directorsArray.controls.forEach((directorArray) =>
      (directorArray as FormArray).controls.forEach((item) =>
        controls.push(item as FormControl)
      )
    );
    return controls;
  }
  // form
  get formControls(): FormControl[] {
    let controls: FormControl[] = [];
    const group = this.personalGroup ?? this.corporateGroup;
    if (group) {
      Object.values<any>(group.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    if (this.accountGroup) {
      Object.values<any>(this.accountControl.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    if (this.counterpartyGroup) {
      Object.values<any>(this.counterpartyControl.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    if (this.directorGroup) {
      Object.values<any>(this.directorControl.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    if (this.closeLinkGroup) {
      Object.values<any>(this.closeLinkControl.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    if (this.directorCloseLinkGroup) {
      Object.values<any>(this.directorCloseLinkControl.controls).forEach(
        (value) => {
          if (value.controls) {
            Object.values(value.controls).forEach((value) =>
              controls.push(value as FormControl)
            );
          } else {
            controls.push(value as FormControl);
          }
        }
      );
    }
    if (this.groupMemberGroup) {
      Object.values<any>(this.groupMemberControl.controls).forEach((value) => {
        if (value.controls) {
          Object.values(value.controls).forEach((value) =>
            controls.push(value as FormControl)
          );
        } else {
          controls.push(value as FormControl);
        }
      });
    }
    return controls;
  }
  // count all the comments
  get formCommentsCount(): number {
    return this.formControls.filter(
      (item) => item.value.onboardingField?.comment
    ).length;
  }

  get isApplicationValid(): boolean {
    return (
      this.documentsArray.valid &&
      this.directorsArray.valid &&
      (this.personalGroup ? this.personalGroup.valid : true) &&
      (this.corporateGroup ? this.corporateGroup.valid : true) &&
      (this.accountGroup ? this.accountGroup.valid : true) &&
      (this.counterpartyGroup ? this.counterpartyGroup.valid : true) &&
      this.generalCommentControl.valid
    );
  }

  get isApplicationPending(): boolean {
    return (
      this.documentsArray.pending ||
      this.directorsArray.pending ||
      (this.personalGroup ? this.personalGroup.pending : false) ||
      (this.corporateGroup ? this.corporateGroup.pending : false) ||
      (this.accountGroup ? this.accountGroup.pending : false) ||
      (this.counterpartyGroup ? this.counterpartyGroup.pending : false) ||
      this.generalCommentControl.pending
    );
  }

  get isNothingRejected(): boolean {
    return (
      this.getRejectedCount(this.documentsControls) +
        this.getRejectedCount(this.directorsDocControls) +
        this.formCommentsCount ===
      0
    );
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));

    forkJoin([
      this.sharedService.getAllCountries(),
      (await this.usersService.getUserData(this.userId)).pipe(
        tap(async (data) => {
          if (!data.wasDisplayed) {
            (
              await this.usersService.markUserAsDisplayed(this.userId)
            ).subscribe();
          }
        })
      ),
    ]).subscribe(
      ([countries, data]) => {
        this.countries = countries;
        this.data = data;
        this.userProgram = data.program;
        // documents
        this.documentsArray = this.fb.array([]);
        data.documents.forEach((doc) =>
          this.documentsArray.push(
            this.fb.control({ ...doc }, adminDocumentValidator())
          )
        );

        // directors
        this.directorsArray = this.fb.array([]);
        this.directors = data.companyDirectors || [];
        this.directors.forEach((director) => {
          const directorDocumentsArray = this.fb.array([]);
          director.documents.forEach((doc) =>
            directorDocumentsArray.push(
              this.fb.control({ ...doc }, adminDocumentValidator())
            )
          );
          this.directorsArray.push(directorDocumentsArray);
        });

        // form
        if (data.personalForm) {
          this.generalComment = data.fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL
          )?.comment; // setting general comment
          this.personalGroup = generateAdminPersonalForm(
            this.fb,
            data.personalForm,
            data.fields,
            this.countries
          );
        } else if (data.corporateForm) {
          this.generalComment = data.fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS
          )?.comment; // setting general comment
          this.isSameAddress =
            JSON.stringify(data.corporateForm.incorporationAddress) ===
            JSON.stringify(data.corporateForm.operatingAddress);

          this.corporateGroup = generateAdminCorporateForm(
            this.fb,
            data.corporateForm,
            data.fields,
            this.countries
          );
          // clear values before re-calling loadData()
          this.accountControl.clear();
          this.counterpartyControl.clear();
          this.directorControl.clear();
          this.closeLinkControl.clear();
          this.directorCloseLinkControl.clear();
          this.groupMemberControl.clear();
          generateAdminAccountForm(
            this.fb,
            this.accountControl,
            data.companyAccounts ? data.companyAccounts : [],
            data.fields,
            this.countries
          );
          generateAdminCounterpartyForm(
            this.fb,
            this.counterpartyControl,
            data.companyCounterparties ? data.companyCounterparties : [],
            data.fields,
            this.countries
          );
          generateAdminDirectorForm(
            this.fb,
            this.directorControl,
            data.companyDirectors ? data.companyDirectors : [],
            data.fields,
            this.countries
          );
          generateAdminCloseLinkForm(
            this.fb,
            this.closeLinkControl,
            data.companyCloseLinks ? data.companyCloseLinks : [],
            data.fields,
            this.countries
          );
          generateAdminCloseLinkCombinationsForm(
            this.fb,
            data.companyCloseLinks ? data.companyCloseLinks : [],
            this.directorCloseLinkControl,
            data.companyDirectorCloseLinks
              ? data.companyDirectorCloseLinks
              : [],
            data.fields,
            this.countries
          );
          generateAdminGroupMemberForm(
            this.fb,
            this.groupMemberControl,
            data.companyGroupMembers ? data.companyGroupMembers : [],
            data.fields,
            this.countries
          );
        }
        this.setViewState(data.state);
        this.isLoading = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isLoading = false;
      }
    );
  }

  isEmptyDobMatches() {
    if (this.data?.dobMatches) {
      return Object.keys(this.data?.dobMatches).length === 0;
    } else return true;
  }

  async loadActionLogs(): Promise<void> {
    (await this.usersService.getUserData(this.userId)).subscribe((data) => {
      if (this.data) {
        this.data.actionLogs = data?.actionLogs;
      }
    });
  }

  async changeCddSuggestionStatus() {
    this.isChangingSuggestionStatus = true;
    (await this.usersService.changeCddSuggestionStatus(this.userId)).subscribe(
      () => {
        this.loadData();
        this.isChangingSuggestionStatus = false;
      },
      (error) => {
        this.isChangingSuggestionStatus = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async changeCddKeyPersonSuggestionStatus() {
    this.isChangingSuggestionStatus = true;
    (
      await this.usersService.changeCddKeyPersonSuggestionStatus(this.userId)
    ).subscribe(
      () => {
        this.loadData();
        this.isChangingSuggestionStatus = false;
      },
      (error) => {
        this.isChangingSuggestionStatus = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async changeCddBusinessSuggestionStatus() {
    this.isChangingSuggestionStatus = true;
    (
      await this.usersService.changeCddBusinessSuggestionStatus(this.userId)
    ).subscribe(
      () => {
        this.loadData();
        this.isChangingSuggestionStatus = false;
      },
      (error) => {
        this.isChangingSuggestionStatus = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async changeEddSuggestionStatus() {
    this.isChangingSuggestionStatus = true;
    (await this.usersService.changeEddSuggestionStatus(this.userId)).subscribe(
      () => {
        this.loadData();
        this.isChangingSuggestionStatus = false;
      },
      (error) => {
        this.isChangingSuggestionStatus = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  makeClientDecision(approve: boolean): void {
    this.dialog
      .open<ClientDecisionDialogComponent, ClientDecisionDialogData, boolean>(
        ClientDecisionDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '720px',
          disableClose: true,
          data: {
            userId: this.userId,
            userName: this.headerText,
            approve: approve,
            isPersonal: this.personalGroup ? true : false,
            isKeyPersonsDecisionPoint:
              this.data?.state === UserState.KEY_PERSONS_DECISION_POINT,
          },
        }
      )
      .beforeClosed()
      .pipe(filter((isVerified) => !!isVerified)) // when cancel is clicked, remain at the same tab
      .subscribe(() => {
        this.router.navigate(['..']); // returns back to ongoing registrations table
      });
  }

  private setViewState(userState: UserState): void {
    switch (userState) {
      case UserState.INITIATED_REGISTRATION:
      case UserState.VERIFIED_EMAIL:
      case UserState.VERIFIED_MOBILE_PHONE_NUMBER:
        this.state = ViewState.VIEW_ONLY; // so can see audit trail
        break;
      case UserState.EDD_SUBMITTED:
      case UserState.BUSINESS_CDD_REVIEW:
        this.state = ViewState.NORMAL;
        break;
      default:
        // add more states here for admin to view only
        this.state = ViewState.VIEW_ONLY;
        this.documentsArray.disable();
        this.directorsArray.disable();
        this.personalGroup?.disable();
        this.corporateGroup?.disable();
        this.accountGroup.disable();
        this.counterpartyGroup.disable();
        this.directorGroup.disable();
        this.closeLinkGroup.disable();
        this.directorCloseLinkGroup.disable();
        this.groupMemberGroup.disable();
        this.generalCommentControl.disable();
    }
  }

  get accountControl(): FormArray {
    return this.accountGroup.get('accountForm') as FormArray;
  }

  get counterpartyControl(): FormArray {
    return this.counterpartyGroup.get('counterpartyForm') as FormArray;
  }

  get directorControl(): FormArray {
    return this.directorGroup.get('directorForm') as FormArray;
  }

  get closeLinkControl(): FormArray {
    return this.closeLinkGroup.get('closeLinkForm') as FormArray;
  }

  get directorCloseLinkControl(): FormArray {
    return this.directorCloseLinkGroup.get(
      'directorCloseLinkForm'
    ) as FormArray;
  }

  get groupMemberControl(): FormArray {
    return this.groupMemberGroup.get('groupMemberForm') as FormArray;
  }
}
