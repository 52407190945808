import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserState } from '../../../login/models/user-state.enum';
import { RegisteredUsersService } from '../../../admin/users/registered-users/registered-users.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';
import { closeReasonNamesMap } from 'src/app/admin/users/registered-users/registered-user/shared/closeReasonNamesMap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-avatar-input-new',
  templateUrl: './avatar-input.component.html',
  styleUrls: ['./avatar-input.component.scss'],
})
export class AvatarInputNewComponent implements OnInit {
  @Input() avatarUrl: string | null = null;
  @Input() isLocked: boolean = false;
  @Input() reviewDate?: string;
  @Input() closeReason?: number;
  @Input() closeDate?: string;

  _errorMessage: string = '';
  get errorMessage() {
    return this._errorMessage;
  }
  @Input() set errorMessage(errorMessage: string) {
    this.setError(errorMessage);
  }

  @Input() isUploading = false;
  @Input() state?: UserState;
  @Input() userId!: number;
  @Input() isAdminProfile: boolean = false;

  @Output() avatarChange = new EventEmitter<File>();
  @Output() avatarRemove = new EventEmitter<void>();
  @Output() reloadUser = new EventEmitter<void>();

  minWidth = 120;
  minHeight = 120;
  maxFileSizeMB = 15;
  isSaving: boolean = false;
  isLockingOrUnlockingUser: boolean = false;
  closeReasonNamesMap = closeReasonNamesMap;
  UserState = UserState;
  userStatesMap: Map<UserState, string>;
  userStateForm: FormGroup;

  private errorTimeout?: any;

  constructor(
    private fb: FormBuilder,
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.userStatesMap = new Map<UserState, string>([
      [UserState.KYC_VERIFIED, 'KYC Verified'],
      [
        UserState.CLOSED,
        `Closed${this.closeDate ? ` on ${this.datePipe.transform(this.closeDate, 'dd/MM/yyyy')}` : ''}`,
      ],
    ]);

    this.userStateForm = this.fb.group({
      userState: [this.state, Validators.required],
      closeReason: [this.closeReason],
    });

    this.userStateControl.valueChanges.subscribe((newUserState) => {
      if (newUserState === UserState.CLOSED) {
        this.closeReasonControl?.setValidators(Validators.required);
      } else {
        this.closeReasonControl?.clearValidators();
        this.closeReasonControl?.setValue(null);
      }
      this.closeReasonControl?.updateValueAndValidity();
    });
  }

  dropped(avatar: NgxFileDropEntry): void {
    if (!this.isUploading && avatar && avatar.fileEntry.isFile) {
      (avatar.fileEntry as FileSystemFileEntry).file((file: File) => {
        const img = new Image();
        var fileReader = new FileReader();
        fileReader.onload = () => {
          img.onload = () => {
            if (img.width < this.minWidth || img.height < this.minHeight) {
              this.setError('Image is too small');
            } else if (file.size > this.maxFileSizeMB * 1024 * 1024) {
              this.setError('File is too big');
            } else {
              this.avatarChange.emit(file);
            }
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(file);
      });
    }
  }

  deleteAvatar(): void {
    this.avatarRemove.emit();
  }

  saveUserState() {
    this.isSaving = true;
    this.registeredUsersService
      .updateStatus(
        this.userId,
        this.userStateControl?.value,
        this.closeReasonControl?.value
      )
      .subscribe(
        () => {
          this.isSaving = false;
          this.userStateForm.markAsPristine();
        },
        (error) => {
          this.isSaving = false;
          this.errorService.showErrorDialog(error.error.message);
        }
      );
  }

  lockOrUnlockUser(): void {
    this.isLockingOrUnlockingUser = true;
    this.registeredUsersService.lockOrUnlockUser(this.userId).subscribe(
      () => {
        this.isLockingOrUnlockingUser = false;
        this.reloadUser.emit();
      },
      (error) => {
        this.isLockingOrUnlockingUser = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  onRightClick(event: MouseEvent): void {
    // prevents the right-click context menu
    event.preventDefault();
  }

  private clearError(): void {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
    this._errorMessage = '';
  }

  private setError(message: string): void {
    this._errorMessage = message;
    this.errorTimeout = setTimeout(() => this.clearError(), 5000);
  }

  get userStateControl(): AbstractControl | null {
    return this.userStateForm.get('userState');
  }

  get closeReasonControl(): AbstractControl | null {
    return this.userStateForm.get('closeReason');
  }
}
