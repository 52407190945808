import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { AccountsDetails } from 'src/app/shared/models/accounts-details.model';
import { ServerSentEventType } from 'src/app/shared/models/server-sent-event-type.enum';
import { UserEventsService } from 'src/app/shared/services/user-events.service';
import { TransferService } from 'src/app/transfer/transfer.service';
import { ActivationSnackbarComponent } from './activation-snackbar/activation-snackbar.component';
import { ActivationFileModel } from './models/activation-file.model';

export enum ActivationState {
  initial,
  fileProcessing,
  fileErroring,
  fileUploaded,
  confirmation,
  transfersInProgress,
  transfersFinished,
  retryInProgress,
  retryFinished,
}

@Component({
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit, OnDestroy {
  state: ActivationState = ActivationState.initial;

  // first view variables
  fromAccount?: AccountsDetails;
  uploadError?: string;
  activationFile?: ActivationFileModel;

  //second view variables
  statusMap: Map<string, boolean> = new Map<string, boolean>(); // <uniqueCode, status>
  failedTransfers: string[] = [];
  successCount: number = 0;
  failureCount: number = 0;
  retrySuccessCount: number = 0;
  retryFailureCount: number = 0;

  ActivationState = ActivationState;

  private initialViewStatesSet = new Set([
    ActivationState.initial,
    ActivationState.fileProcessing,
    ActivationState.fileErroring,
    ActivationState.fileUploaded,
  ]);
  private statesWithTransfersStatusSet = new Set([
    ActivationState.transfersFinished,
    ActivationState.retryInProgress,
    ActivationState.retryFinished,
  ]);

  private eventsSub?: Subscription;

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private transferService: TransferService,
    private userEventsService: UserEventsService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.eventsSub?.unsubscribe();
  }

  // Functions are listed in the order of states:

  onUpload(uploadObs: Observable<ActivationFileModel>): void {
    this.state = ActivationState.fileProcessing;
    this.uploadError = '';
    uploadObs.subscribe(
      (activationFile) => {
        this.state = ActivationState.fileUploaded;
        this.activationFile = activationFile;
      },
      (err) => {
        this.state = ActivationState.fileErroring;
        this.uploadError = err.error.message;
      }
    );
  }

  goToConfirmationStep(): void {
    this.state = ActivationState.confirmation;
  }

  cancel(): void {
    this.state = ActivationState.initial;
  }

  confirm(): void {
    this.state = ActivationState.transfersInProgress;
    this.sendActivationCodes(false);
  }

  goToTransfersFinishedStep(): void {
    this.state = ActivationState.transfersFinished;
  }

  retry(): void {
    this.state = ActivationState.retryInProgress;
    this.sendActivationCodes(true);
  }

  goToRetryFinishedStep(): void {
    this.state = ActivationState.retryFinished;
    this.snackBar.openFromComponent(ActivationSnackbarComponent, {
      data: {
        successCount: this.retrySuccessCount,
        failureCount: this.retryFailureCount,
      },
      panelClass: 'g-snackbar',
    });
  }

  private sendActivationCodes(isRetry: boolean): void {
    const eventsCount = isRetry
      ? this.failureCount
      : this.activationFile!.lines.length;

    // Events subscription
    this.eventsSub?.unsubscribe();
    this.eventsSub = this.userEventsService.userEventsObservable
      .pipe(
        map((ev) => JSON.parse(ev)),
        filter(
          (ev) =>
            ev.type === ServerSentEventType.SEND_ACTIVATION_CODE &&
            ev.code === this.activationFile?.code
        ),
        take(eventsCount)
      )
      .subscribe(
        (ev) => {
          this.ngZone.run(() => {
            if (isRetry) {
              if (ev.sendSmsStatus) {
                this.retrySuccessCount++;
                this.successCount++;
                this.failureCount--;
              } else {
                this.retryFailureCount++;
              }
            } else {
              if (ev.sendSmsStatus) {
                this.successCount++;
              } else {
                this.failureCount++;
                this.failedTransfers.push(ev.lineUniqueId);
              }
            }
            this.statusMap = this.statusMap.set(
              ev.lineUniqueId,
              ev.sendSmsStatus
            );
          });
        },
        () => this.ngZone.run(() => this.errorService.showErrorDialog()),
        () =>
          this.ngZone.run(() =>
            isRetry
              ? this.goToRetryFinishedStep()
              : this.goToTransfersFinishedStep()
          )
      );

    if (isRetry) {
      // resend activation codes for failed attempts
      // loop backwards because elements are removed during iteration
      for (var i = this.activationFile!.lines.length - 1; i >= 0; i--) {
        if (
          !this.failedTransfers.includes(
            this.activationFile!.lines[i].lineUniqueId
          )
        ) {
          this.activationFile!.lines.splice(i, 1);
        }
      }
    }

    this.transferService
      .sendActivationCodeExecute(
        this.activationFile!,
        this.activationFile!.code
      )
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  get isInitialView(): boolean {
    return this.initialViewStatesSet.has(this.state);
  }
  get isShowingTransfersStatus(): boolean {
    return this.statesWithTransfersStatusSet.has(this.state);
  }
}
